<template>
  <div class="tw-flex tw-min-h-screen tw-flex-col tw-bg-gray-50">
    <V2HeaderNav class="tw-py-2 lg:tw-py-0" :cobranded-experience="cobrandedExperience" />
    <div class="tw-relative tw-flex tw-flex-1 tw-items-start tw-justify-center">
      <section
        class="tw--mt-3 tw-w-full tw-rounded-lg lg:tw-mt-0 lg:tw-w-3/4 lg:tw-px-6 lg:tw-pt-8"
      >
        <div class="tw-min-h-full tw-pb-16 sm:tw-flex lg:tw-pb-0">
          <main class="tw-relative tw-flex tw-flex-1 tw-flex-col">
            <slot />
          </main>
        </div>
      </section>
    </div>

    <!-- Desktop footer -->
    <div class="tw-flex tw-w-full tw-items-center tw-justify-center tw-pt-10">
      <V2AuthenticatedFooter class="tw-hidden lg:tw-block lg:tw-w-3/4" />
    </div>

    <!-- Tablet and mobile navigation -->
    <div
      class="tw-fixed tw-bottom-0 tw-h-[72px] tw-w-full tw-border-t-2 tw-border-gray-200 tw-bg-white lg:tw-hidden"
    >
      <V2Navigation />
    </div>

    <AppToast />
  </div>
</template>

<script setup lang="ts">
const branding = useBrand()
const cobrandedExperience = computed(() => branding.value.brandingExperience === 'cobrand')

// Use the composable for dynamic head
const { applyHead } = useDynamicHead()
applyHead()
</script>
